<template>
     <div v-loading="isLoadingItems">
        <div class="likk">
            <div class="title_madal">{{ $t('message.drug') }}</div>
            <ul>
                <li v-for="(item, index) in getProducts" :key="index + ' products'">
                    <span>{{ index + 1 }})</span>
                    <b>{{ item.name }}</b>
                </li>
            </ul>
        </div>
        <div class="prepare_li">
            <div class="title_madal">{{ $t('message.how_prepare_medicine') }}</div>
            <span>{{ data.detail }}</span>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: ['model'],
    data(){
        return {
            data: {},
            isLoadingItems: false,
        }
    },
    computed: {
        getProducts(){
            let products = [];
            let names = [];
            if (this.data) {
                if (_.isArray(this.data.products)) {
                    products = this.data.products;   
                }                
                if (_.isArray(this.data.dailyTreatmentNames)) {
                    names = this.data.dailyTreatmentNames;   
                }                
            }
            return this.data ? [...products,...names] : []
        }
    },
    methods: {
        ...mapActions({
            showModel: 'dailyTreatments/showItem',
        }),
        afterOpen(){
            this.load();
        },
        load(){
            if (!this.isLoadingItems && this.model) {
                this.isLoadingItems = true;
                this.showModel(this.model.id)
                    .then((res) => {
                        this.isLoadingItems = false;
                        this.data = res.data.dailyTreatment;
                    })
                    .catch(err => {
                        this.isLoadingItems = false;
                        this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                    });
            }
        }
    },
}
</script>